import styled from 'styled-components'

export const FooterContainer = styled.footer`
  background-color: #111;
  color: white;
  padding: 20px 0;
  text-align: center;
`

export const Branding = styled.div`
  font-size: 1.5rem;
  margin-bottom: 10px;
`

export const Description = styled.p`
  font-size: 0.9rem;
  margin-bottom: 20px;
`

export const SocialContainer = styled.div`
  font-size: 1.5rem;
  & > * {
    margin: 0 10px;
    color: white;
    transition: color 0.3s;

    &:hover {
      color: #00a7e1;
    }
  }
`

export const CopyRight = styled.p`
  font-size: 0.8rem;
  margin-top: 20px;
`
