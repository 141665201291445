import React from 'react'
import { FaLinkedin, FaGithubSquare, FaEnvelopeSquare } from 'react-icons/fa'

import {
  FooterContainer,
  Branding,
  Description,
  SocialContainer,
  CopyRight
} from './Footer.style'

const Footer = () => {
  return (
    <FooterContainer>
      <Branding>Cătălin-Eugen Nedelea</Branding>
      <Description>
        Crafting digital experiences with passion, precision, and modern
        technology.
      </Description>
      <SocialContainer>
        <a
          href='https://www.linkedin.com/in/cătălinnedelea/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaLinkedin />
        </a>

        <a
          href='https://github.com/CatalinNedelea'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaGithubSquare />
        </a>

        <a href='mailto:nedelea.catalin98@gmail.com'>
          <FaEnvelopeSquare />
        </a>
      </SocialContainer>
      <CopyRight>
        © {new Date().getFullYear()}. Made with dedication by Cătălin-Eugen
        Nedelea
      </CopyRight>
    </FooterContainer>
  )
}

export default Footer
