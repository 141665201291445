export const expertiseAreas = [
  {
    title: 'Full-Stack Development',
    text: 'Engineering robust backend systems with Node.js and crafting interactive front-ends using React.js.'
  },
  {
    title: 'Cross-Platform Apps',
    text: 'Designing and building responsive, high-performance applications with React Native for a unified brand experience across all platforms.'
  },
  {
    title: 'Agile Workflows',
    text: 'Implementing agile methodologies to optimize project flow and enhance collaboration across development teams.'
  },
  {
    title: 'Cutting-Edge Technologies',
    text: 'Continuously learning and integrating emerging technologies to create innovative and scalable solutions.'
  },
  {
    title: 'User Experience (UX)',
    text: 'Creating intuitive, accessible, and user-friendly interfaces that facilitate seamless user experiences.'
  },
  {
    title: 'Data-Driven Development',
    text: 'Employing comprehensive analytics and data management strategies to inform user-centric development.'
  }
]
