import styled from 'styled-components'

export const ContactSection = styled.section`
  background-color: #333; // A dark solid color for the background
  color: white;
  padding: 80px 0;
  text-align: center;
`

export const Title = styled.h2`
  font-size: 3.5rem;
  margin-bottom: 1rem;
`

export const Subtitle = styled.p`
  font-size: 1.2rem;
  padding: 2rem;
`

export const Form = styled.form`
  max-width: 600px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`

export const Input = styled.input`
  width: 80%;
  padding: 10px;
  margin-bottom: 1rem;
  border: none;
  border-bottom: 2px solid #555;
  background-color: transparent;
  color: white;
  font-size: 1rem;

  &::placeholder {
    color: #aaa;
  }
`

export const Textarea = styled.textarea`
  width: 80%;
  padding: 10px;
  margin-bottom: 1rem;
  border: none;
  border-bottom: 2px solid #555;
  background-color: transparent;
  color: white;
  font-size: 1rem;
  height: 150px;

  &::placeholder {
    color: #aaa;
  }
`

export const SubmitButton = styled.button`
  background-color: #00a7e1;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #008db1;
  }
`
export const ConfirmationPopup = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: slideUpFadeIn 0.5s ease-in-out both;
  background-color: ${props =>
    props.success ? '#00c851' : '#ff4444'}; // Green for success, red for error

  @keyframes slideUpFadeIn {
    0% {
      opacity: 0;
      bottom: 10px;
    }
    100% {
      opacity: 1;
      bottom: 20px;
    }
  }
`
export const RecaptchaContainer = styled.div`
  /* position: fixed;
  left: 50%;
  top: 50%; */
  /* transform: translate(-50%, -50%); */
  margin-top: 1rem;
  margin-bottom: 2rem;
  z-index: 100; // Ensure it's above other elements
`
