import { Link } from 'react-scroll'
import styled, { keyframes } from 'styled-components'

export const animStar = keyframes`
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
`

export const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`

export const Star = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background: transparent;
  box-shadow: ${({ boxShadow }) => boxShadow};
  animation: ${animStar} ${({ time }) => time}s linear infinite;
  position: absolute; // Ensure star is positioned absolutely within the wrapper
`

export const Wrapper = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  z-index: 0; // Lower z-index so content on top can be visible
`

export const HomeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 1; // Higher z-index to make sure the text appears above the stars
`

export const NameProfessionWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`

export const Name = styled.h1`
  font-size: 5rem;
  color: white;
  margin: 0 0 0.5rem 0; // Add a small margin at the bottom for spacing

  @media (max-width: 1024px) {
    font-size: 3.2rem;
  }

  @media (max-width: 768px) {
    font-size: 3.2rem;
  }
`

export const Profession = styled.h2`
  font-size: 2rem;
  color: white;
  margin: 0; // Remove margin if any

  @media (max-width: 1024px) {
    font-size: 1.5rem;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`

export const ScrollIndicator = styled(Link)`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 2rem;
  animation: ${bounce} 2s infinite;
  cursor: pointer;
`
