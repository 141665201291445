import React, { useRef } from 'react'
import { Home, About, WhatIDo, Projects, Contact, Footer } from './components'

const App = () => {
  const contactRef = useRef(null)

  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <Home />
      <About scrollToContact={scrollToContact} />
      <WhatIDo />
      <Projects />
      <Contact ref={contactRef} />
      <Footer />
    </>
  )
}

export default App
