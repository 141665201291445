import React from 'react'
import {
  WhatIDoSection,
  Title,
  ExpertiseGrid,
  ExpertiseItem,
  ExpertiseText,
  ExpertiseTitle
} from './WhatIDo.style'
import { expertiseAreas } from '../consts'

const WhatIDo = () => {
  return (
    <WhatIDoSection>
      <Title>What I Do</Title>
      <ExpertiseGrid>
        {expertiseAreas.map((area, index) => (
          <ExpertiseItem key={index}>
            <ExpertiseTitle>{area.title}</ExpertiseTitle>
            <ExpertiseText>{area.text}</ExpertiseText>
          </ExpertiseItem>
        ))}
      </ExpertiseGrid>
    </WhatIDoSection>
  )
}

export default WhatIDo
