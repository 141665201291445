import styled from 'styled-components'

export const AboutSection = styled.section`
  id: 'about-section'; // ID for react-scroll to target
  padding: 4em 10%;
  text-align: center;
  background-color: #f5f5f5;
`

export const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 0.5em;
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    display: block;
    width: 50px;
    height: 4px;
    background-color: #00a7e1;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
  }
`

export const SubTitle = styled.p`
  margin-bottom: 2em;
  font-size: 1.2rem;
`

export const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2em;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`

export const Column = styled.div`
  flex-basis: 48%;
`

export const SkillsList = styled.ul`
  list-style: none;
  padding: 0;
  text-align: left;

  @media (max-width: 1024px) {
    text-align: center;
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`

export const SkillItem = styled.li`
  background-color: #eee;
  display: inline-block;
  margin: 0.5em;
  padding: 0.3em 1em;
  border-radius: 2em;
  font-weight: bold;
`

export const Button = styled.button`
  background-color: #00a7e1;
  color: white;
  border: none;
  padding: 1em 1.5em;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  margin-top: 20px; // Add a little space above the button
  transition: background 0.3s ease;

  &:hover {
    background-color: #0077b3;
  }
`
