import React from 'react'
import {
  AboutSection,
  Title,
  SubTitle,
  Column,
  Columns,
  SkillItem,
  SkillsList,
  Button
} from './About.style'

const About = ({ scrollToContact }) => {
  const skills = [
    'ReactJS',
    'JavaScript',
    'TypeScript',
    'Python',
    'MongoDB',
    'WebSocket',
    'RESTful APIs',
    'HTML',
    'SCSS',
    'styled-components',
    'Formik',
    'Agile',
    'Git',
    'ExpressJS',
    'NodeJS',
    'React Native',
    'Firebase',
    'Redux'
  ]

  return (
    <AboutSection id='about-section'>
      <Title>About Me</Title>
      <SubTitle>Crafting intuitive experiences through code.</SubTitle>
      <Columns>
        <Column>
          <p>
            As a software developer with a sharp eye for detail and a robust
            track record, I specialize in bringing to life scalable, efficient
            web and mobile applications. My passion for development is matched
            by a strong foundation in both the creative and technical realms of
            programming, ensuring each project not only functions seamlessly but
            also engages and delights users. With proficiency in a variety of
            modern technologies and a commitment to staying at the forefront of
            industry trends, I bring a dynamic skill set to every endeavor.
            Whether simplifying complex problems or leading projects to new
            heights, my approach is always characterized by dedication,
            innovation, and a drive to exceed expectations.
          </p>
        </Column>
        <Column>
          <SkillsList>
            {skills.map(skill => (
              <SkillItem key={skill}>{skill}</SkillItem>
            ))}
          </SkillsList>
        </Column>
      </Columns>
      <Button onClick={scrollToContact}>Contact Me</Button>
    </AboutSection>
  )
}

export default About
