import React, { useState, useEffect } from "react";
import projectData from "./projects.json";
import {
  ProjectCard,
  ProjectDescription,
  ProjectGrid,
  ProjectImage,
  ProjectInfo,
  ProjectTitle,
  ProjectsSection,
  Tag,
  Title,
  TagsContainer,
  ModalBackdrop,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalImage,
  ModalLink,
  ModalParagraph,
  ModalTagList,
} from "./Projects.style";

const Projects = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const openModal = (project) => {
    setSelectedProject(project);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedProject(null);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = isModalOpen ? "hidden" : "auto";
  }, [isModalOpen]);

  return (
    <ProjectsSection>
      <Title>Projects</Title>
      <ProjectGrid>
        {projectData.map((project) => (
          <ProjectCard key={project.id} onClick={() => openModal(project)}>
            <ProjectImage src={project.imageUrl} alt={project.title} />
            <ProjectInfo>
              <ProjectTitle>{project.title}</ProjectTitle>
              <ProjectDescription>{project.description}</ProjectDescription>
              <TagsContainer>
                {project.tags.map((tag, index) => (
                  <Tag key={index}>{tag}</Tag>
                ))}
              </TagsContainer>
            </ProjectInfo>
          </ProjectCard>
        ))}
      </ProjectGrid>
      {isModalOpen && selectedProject && (
        <ModalBackdrop show={isModalOpen} onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <ModalCloseButton onClick={closeModal}>&times;</ModalCloseButton>
            <ModalHeader>{selectedProject.title}</ModalHeader>
            <ModalImage
              src={selectedProject.imageUrl}
              alt={selectedProject.title}
            />
            <ModalParagraph>{selectedProject.description}</ModalParagraph>
            <ModalParagraph>{selectedProject.about}</ModalParagraph>
            <ModalTagList>
              {selectedProject.technologies.map((tech, index) => (
                <Tag key={index}>{tech}</Tag>
              ))}
            </ModalTagList>
            <div>
              {selectedProject.websiteUrl && (
                <ModalLink href={selectedProject.websiteUrl} target="_blank">
                  Website
                </ModalLink>
              )}
              {selectedProject.githubUrl && (
                <ModalLink href={selectedProject.githubUrl} target="_blank">
                  GitHub
                </ModalLink>
              )}
            </div>
          </ModalContent>
        </ModalBackdrop>
      )}
    </ProjectsSection>
  );
};

export default Projects;
