import React from 'react'
import { FaArrowDown } from 'react-icons/fa'
import {
  Star,
  Wrapper,
  HomeContainer,
  Name,
  NameProfessionWrapper,
  Profession,
  ScrollIndicator
} from './Home.style'

const generateStars = (count, size, time) => {
  let value = ''
  for (let i = 0; i < count; i++) {
    value += `${Math.floor(Math.random() * 2000)}px ${Math.floor(
      Math.random() * 2000
    )}px #FFF, `
  }
  value = value.slice(0, -2)
  return <Star size={size} boxShadow={value} time={time} />
}

const Home = () => {
  return (
    <Wrapper>
      {generateStars(200, 1, 50)}
      {generateStars(100, 2, 100)}
      {generateStars(50, 3, 150)}
      <HomeContainer>
        <NameProfessionWrapper>
          <Name>Nedelea Cătălin-Eugen</Name>
          <Profession>Front End Developer</Profession>
        </NameProfessionWrapper>
        <ScrollIndicator to='about-section' smooth={true} duration={500}>
          <FaArrowDown />
        </ScrollIndicator>
      </HomeContainer>
    </Wrapper>
  )
}

export default Home
