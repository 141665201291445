import styled from 'styled-components'

export const WhatIDoSection = styled.section`
  padding: 80px 0;
  position: relative;
  text-align: center;
  background: #111;
`

export const Title = styled.h2`
  font-size: 3rem;
  color: #fff;
  z-index: 10;
  position: relative;
`

export const ExpertiseGrid = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    justify-content: center;
  }

  @media (max-width: 768px) {
    justify-content: center;
    margin: 1rem;
  }
`

export const ExpertiseItem = styled.div`
  background-color: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  max-width: 30rem;

  &:hover {
    transform: translateY(-5px);
  }
`

export const ExpertiseTitle = styled.h3`
  font-size: 1.5rem;
  color: #00a7e1;
  margin-bottom: 1rem;
`

export const ExpertiseText = styled.p`
  font-size: 1rem;
  line-height: 1.6;
`
