import React, { useState, useRef, useEffect } from 'react'
import emailjs from '@emailjs/browser'
import {
  ContactSection,
  Textarea,
  Title,
  SubmitButton,
  Subtitle,
  Form,
  Input,
  ConfirmationPopup,
  RecaptchaContainer
} from './Contact.style'

const RATE_LIMIT_TIME = 30 * 60 * 1000 // 30 minutes in milliseconds
// const RATE_LIMIT_TIME = 30 * 1000 // 30 seconds in milliseconds

const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY

const Contact = React.forwardRef((props, ref) => {
  const formRef = useRef()
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_subject: '',
    message: ''
  })

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [messageStatus, setMessageStatus] = useState({
    show: false,
    success: false,
    message: ''
  })

  const recaptchaInstance = useRef(null)

  useEffect(() => {
    const loadReCaptchaScript = () => {
      if (window.grecaptcha && recaptchaInstance.current !== null) {
        return
      }

      const script = document.createElement('script')
      script.src = 'https://www.google.com/recaptcha/api.js?render=explicit'
      script.async = true
      script.defer = true
      script.onload = () => {
        renderReCaptcha()
      }
      document.head.appendChild(script)
    }

    const renderReCaptcha = () => {
      if (window.grecaptcha && recaptchaInstance.current === null) {
        recaptchaInstance.current = window.grecaptcha.ready(() => {
          window.grecaptcha.render('recaptcha-container', {
            sitekey: recaptchaKey
          })
        })
      } else {
      }
    }

    loadReCaptchaScript()
  }, [])

  const handleChange = event => {
    const { name, value } = event.target
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }))
  }

  const handleSubmit = event => {
    event.preventDefault()
    setIsSubmitting(true)

    const lastSent = localStorage.getItem('lastSent')
    if (lastSent && Date.now() - lastSent < RATE_LIMIT_TIME) {
      alert('You must wait 30 minutes before sending another message.')
      setIsSubmitting(false)
      return
    }

    if (window.grecaptcha) {
      const response = window.grecaptcha.getResponse()

      if (!response) {
        alert('Please solve the reCAPTCHA.')
        setIsSubmitting(false)
        return
      }
    } else {
      setIsSubmitting(false)
      return
    }
    const userID = process.env.REACT_APP_EMAILJS_USER_ID
    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID
    const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID

    emailjs
      .sendForm(serviceID, templateID, event.target, userID)
      .then(
        result => {
          console.log('Email successfully sent!', result.text)
          setMessageStatus({
            show: true,
            success: true,
            message: 'Your message has been sent successfully!'
          })
          localStorage.setItem('lastSent', Date.now())
          resetForm()

          setTimeout(() => {
            setMessageStatus(prevStatus => ({
              ...prevStatus,
              show: false
            }))
          }, 5000) // 5000 milliseconds is 5 seconds
        },
        error => {
          console.error('Failed to send email. Error:', error)
          setMessageStatus({
            show: true,
            success: false,
            message: 'Failed to send your message. Please try again later.'
          })
        }
      )
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const resetForm = () => {
    setFormData({
      user_name: '',
      user_email: '',
      user_subject: '',
      message: ''
    })
  }

  return (
    <ContactSection ref={ref}>
      <Title>Get In Touch</Title>
      <Subtitle>
        Have a sweet project in mind or just want to say hi? Feel free to send
        me a message!
      </Subtitle>
      {messageStatus.show && (
        <ConfirmationPopup success={messageStatus.success}>
          {messageStatus.message}
        </ConfirmationPopup>
      )}
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Input
          type='text'
          name='user_name'
          placeholder='NAME'
          required
          value={formData.user_name}
          onChange={handleChange}
        />
        <Input
          type='email'
          name='user_email'
          placeholder='EMAIL'
          required
          value={formData.user_email}
          onChange={handleChange}
        />
        <Input
          type='text'
          name='user_subject'
          placeholder='SUBJECT'
          required
          value={formData.user_subject}
          onChange={handleChange}
        />
        <Textarea
          name='message'
          placeholder='MESSAGE'
          required
          value={formData.message}
          onChange={handleChange}
        />
        <RecaptchaContainer id='recaptcha-container'></RecaptchaContainer>
        <SubmitButton type='submit' disabled={isSubmitting}>
          Send Message
        </SubmitButton>
      </Form>
    </ContactSection>
  )
})

export default Contact
