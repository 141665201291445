import styled from "styled-components";

export const ProjectsSection = styled.section`
  padding: 80px 0;
  background: #111;
  color: white;
  text-align: center;
`;

export const Title = styled.h2`
  font-size: 3rem;
  margin-bottom: 50px;
`;

export const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); // Three columns
  gap: 20px;
  justify-items: center;
  align-items: start;
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); // Two columns for tablets
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr; // One column for mobile
  }
`;

export const ProjectCard = styled.div`
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.3s ease, transform 0.3s ease,
    background-color 0.3s ease;
  cursor: pointer; /* Indicates that an item is clickable */
  background: rgba(255, 255, 255, 0.1);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.5);
    background-color: #333; /* Darker background on hover */
  }
  width: 20rem;
  min-height: 28rem;
`;

export const ProjectImage = styled.img`
  width: 100%;
  height: 160px;
  object-fit: cover;
`;

export const ProjectInfo = styled.div`
  padding: 15px;
`;

export const ProjectTitle = styled.h3`
  font-size: 1.2rem;
  margin: 0;
`;

export const ProjectDescription = styled.p`
  font-size: 0.9rem;
  line-height: 1.4;
`;

export const TagsContainer = styled.div`
  margin-top: 10px;
`;

export const Tag = styled.span`
  display: inline-block;
  background: #333;
  color: #ddd;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 0.7rem;
  margin-right: 5px;
  margin-top: 1rem;
`;

export const ModalBackdrop = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 20;
  overflow-y: auto;

  @media only screen and (max-width: 600px) {
    height: 100%;
    background: #202020;
  }
`;

export const ModalContent = styled.div`
  background: #202020;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 90%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 600px) {
    padding-top: 3rem;
    height: 100%;
  }
`;

export const ModalCloseButton = styled.button`
  background: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  top: 10px;
  align-self: end;
  display: flex;
`;

export const ModalHeader = styled.h3`
  font-size: 1.5rem;
  color: #eee;
`;

export const ModalImage = styled.img`
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
  margin-bottom: 1rem;
`;

export const ModalTagList = styled.div`
  margin: 10px 0;
`;

export const ModalLink = styled.a`
  display: inline-block;
  margin-top: 10px;
  background: #333;
  color: #ddd;
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
  margin-right: 10px;
  &:hover {
    background: #555;
  }
`;

export const ModalParagraph = styled.p`
  line-height: 1.5;
  margin-bottom: 10px;
`;
